<template>
  <div class="page-products__products">
    <v-row no-gutters class="elevation-2 mt-2 page__table-container">
      <HeaderTableTabs :currentTab.sync="currentTab" :tabs="tabs" />

      <v-col cols="12">
        <Resource
          :countt="parseInt(countt)"
          :resourceType="resourceType"
          :document="document"
          :filter-fields="null"
          :filter-data="filterData"
          :filter-callback="setFilterData"
          :searchable="searchableField"
          :columns="columns"
          :update-router-callback="getUpdateRouterData"
          :callback-action="getAction"
          :actionItems="items"
          :routeName="updateRouteName"
          @getType="getType"
        />
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between">
        <limit-page :callback="setLimit" />
        <pagination
          :length="lengthPage"
          :visible="0"
          v-if="!$parent.isLoading && document"
          :page.sync="page"
        ></pagination>
      </v-col>
    </v-row>

    <delete-action :content="ids.length + ' collection?'" title="Delete collections" />
  </div>
</template>

<script>
import Resource from '@/components/Resources';
import resourceListMixin from '@/mixins/resource-list';
import { collectionApi } from '@/apis/collection';

export default {
  components: { Resource },
  mixins: [resourceListMixin],
  data() {
    return {
      selectAll: '',
      ids: [],
      showDialogDelete: false,
      items: [
        { title: 'Make collections available', action: 'available' },
        { title: 'Make collections unavailable', action: 'unavailable' },
        { title: 'Delete selected collections', action: 'remove-collections' },
      ],
      resourceType: 'collections',
      // page: 1,
      tabs: [
        { name: 'All', key: '' },
        { name: 'Manual collections', key: 'normal' },
        { name: 'Automated collections', key: 'smart' },
      ],
      testVuex: null,
      columns: {
        Selectable: {
          blankLabel: true,
          kind: 'selectable',
          width: 1,
        },
        Collections: {
          field: 'title',
          kind: 'viewCollection',
        },
        Available: {
          field: 'published',
          kind: 'status',
        },
        Conditions: {
          field: 'rules',
          kind: 'rules',
        },
        // Vendor: {
        //   field: 'vendor',
        //   kind: 'text',
        // },
        // Actions: {
        //   kind: 'actions',
        // },
      },
      updateRouteName: 'website.collections.update',
      searchableField: 'title_search',
      sortable: [],
    };
  },
  created() {},
  methods: {
    getType(data) {
      this.selectAll = data;
    },
    async submitDelete() {
      try {
        let params = {
          ids: this.ids,
        };
        let data = await collectionApi.removeCollections(params);

        await this.getResources();
      } catch (error) {}
      this.showDialogDelete = false;
    },
    async updatePublicCollections(available, ids) {
      try {
        let params = {
          action: available === 'available' ? 'makeAvailable' : 'makeUnavailable',
          collectionIds: ids,
        };
        // để thêm search và type all lên url
        let data = await collectionApi.updatePublicCollections(params, this.searchString, this.selectAll);
        await this.getResources();
      } catch (error) {
        console.log(error);
      }
    },
    async getAction(action, ids) {
      switch (action.action) {
        case 'remove-collections':
          this.showDialogDelete = true;
          this.ids = ids;
          break;
        case 'available':
          this.updatePublicCollections('available', ids);
          break;
        case 'unavailable':
          this.updatePublicCollections('unavailable', ids);
          break;
        default:
          break;
      }
    },
    // getUpdateRouterData(id) {
    //   console.log('tuanson', id);
    // },
  },
};
</script>
<style lang="scss">
.page-products__products {
  background: white;
}
</style>
